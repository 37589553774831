import React, { useRef, useState } from 'react';
import Logo from '../../Images/janalogo3.png';
import { Outlet, Link } from 'react-router-dom';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import {MdCall} from 'react-icons/md' ;
import {HiMail} from 'react-icons/hi';
// import {Image1} from '../../Images/img1.jpeg';


const Nav = () => {
  
    let [open,setOpen]=useState(false);

      // Header Slides
      const slides = [
        
        {
          url :"./Images/IMG_6152.JPG"
        },
        {
          url :"./Images/IMG_2473.JPG"
        },
        {
          url :"./Images/DSC03772.jpg"
        },
        {
          url :"./Images/IMG_2470.JPG"
        },
        {
          url :"./Images/DSC03765.jpg"
        },
             
      ];
      const delay = 6000;
    
      const [currentIndex, setCurrentIndex] = useState(0);
      const timeoutRef = useRef(null);

      function resetTimeout(){
        if(timeoutRef.current){
          clearTimeout(timeoutRef.current)
        }
      }
      React.useEffect(() =>{
        resetTimeout();
        timeoutRef.current=setTimeout(()=>
        setCurrentIndex((prevIndex)=>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1 ),
        delay);
        return () =>{
          resetTimeout();
        }
      }, );
    
      const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };
    
      const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
    
      const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
      };

  return (
  <div className=''>
       <div className='absolute top-0 z-20 w-full'>
       <div className=' w-full bg-cyan-900  top-0 '>
          <span className='flex flex-wrap justify-around items-center'>
              <p className='text-slate-100 font-gabefont italic font-bold text-xs sm:text-sm md:text-md lg:text-xl p-3 flex flex-wrap items-center gap-3 '><HiMail className=' h-5 w-8 sm:h-6 md:h-8  text-amber-300'/>Janakihall0327@gmail.com</p>
              <p className='text-slate-100 font-gabefont italic font-bold text-xs sm:text-sm md:text-md lg:text-xl p-3 flex flex-wrap items-center gap-3 '>
                <p className='flex items-center'><MdCall className=' h-5 sm:h-6 md:h-8  w-8 text-amber-300'/>+91 88386 74574</p>
                <p className='flex items-center'><MdCall className='  h-5  sm:h-6 md:h-8 w-8 text-amber-300'/>+91 88705 57892</p>
              </p>
          </span>
        </div> 

      <div className='shadow-xl h-26 md:h-20  w-full  bg-slate-100     left-0  z-20'>
          <div className='md:flex  items-center justify-between md:px-20'>
             <div className=' flex items-center  justify-around'>
             <div className='cursor-pointer'>
              <img src={Logo} alt="Logo"  className=" h-16 md:h-28 w-28 md:w-60 lg:w-40 "/>
             </div>
      
              <div onClick={()=>setOpen(!open)} className='text-3xl right-8 top-0 cursor-pointer md:hidden'>
                  <ion-icon name={open ? 'close':'menu'}></ion-icon> 
              </div>
             </div>

              <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-slate-100 md:z-auto z-[20] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? ' min-[400px]:top-68':'top-[-490px]'}`}>
                
                    <li  className='md:ml-8 md:text-md text-xl font-bold text-cyan-900 hover:text-2xl md:my-0 my-7'>
                      <Link to="/" className='duration-500' >Home</Link>
                    </li>
                    <li  className='md:ml-8  md:text-md text-xl font-bold text-cyan-900  hover:text-2xl duration-300 md:my-0 my-7 '>
                      <Link to="/About">About Us</Link>
                    </li>

                    <li  className='md:ml-8  md:text-md text-xl font-bold text-cyan-900  hover:text-2xl duration-300 md:my-0 my-7'>
                      <Link to="/Facilities">Facilities</Link>
                    </li>  
                    <li  className='md:ml-8 md:text-md text-xl font-bold text-cyan-900  hover:text-2xl duration-300 md:my-0 my-7'>
                      <Link to="/Gallery" >Gallery</Link>
                    </li>   

                    <li  className='md:ml-8 md:text-md text-xl font-bold text-cyan-900 md:my-0 my-7  hover:text-2xl duration-300'>
                      <Link to="/Contact">Contact Us</Link>
                    </li>    
                    <li  className='md:ml-8 text-xl md:text-md font-bold text-cyan-900 md:my-0 my-7  hover:text-2xl duration-300'>
                      <Link to="/Additionalservice">Additional Services</Link>
                    </li>      
            
              </ul>
          </div>
      </div>
       </div>

        {/* Header Slides */}

      <section className='min-[200px]:mt-36 min-[520px]:mt-24  '>        
            <div className='h-[20rem] sm:h-[30rem] lg:h-[56rem] w-full m-auto  relative group'>
              <div
                style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                className='w-full h-full bg-center bg-cover duration-500'
              ></div>
              {/* Left Arrow */}
              <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl  p-2 bg-black/20 text-white cursor-pointer'>
                <BsChevronCompactLeft onClick={prevSlide} size={30} />
              </div>
              {/* Right Arrow */}
              <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl  p-2 bg-black/20 text-white cursor-pointer'>
                <BsChevronCompactRight onClick={nextSlide} size={30} />
              </div>
              <div className='flex top-4 justify-center py-2'>
                {slides.map((slide, slideIndex) => (
                  <div
                    key={slideIndex}
                    onClick={() => goToSlide(slideIndex)}
                    className='text-2xl cursor-pointer'
                  >
                    <RxDotFilled />
                  </div>
                ))}
              </div>
            </div>
              </section>
     
    <Outlet/>
  </div>
  )
}

export default Nav;
