import React from 'react';
import { Link } from 'react-router-dom';
import Lightimage from '../../Images/DSC03761.jpg';
import Airconditioner from '../../Images/air-conditioner.png';
import Electricgenerator from '../../Images/electric-generator.png';
import Table from '../../Images/restaurant.png';
import Room from '../../Images/room.png';
import Dining from '../../Images/IMG_2468.JPG';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import Image1 from '../../Images/IMG_6162.JPG';
import Image2 from '../../Images/IMG_6155.JPG';
import Image3 from '../../Images/IMG_7670.JPG';



const Home = () => {

  return (
    <div className='mt-24'>
      <div className=' w-100% '>   
                             
            {/* Place to remember */}

            <section className='mb-10'>
              <div className='flex flex-wrap justify-between items-center gap-20  md:gap-10 lg:gap-5  '>
              <div className='bg-slate-100 h-[10rem] min-[200px]:h-[20rem] md:h-[30rem] w-24 sm:w-52 md:w-64  lg:w-96 '>
                  <div className=' mt-6 sm:mt-14 md:mt-28 lg:mt-[5rem]  min-[200px]:w-[13rem] min-[300px]:w-[17rem] min-[400px]:w-[20em] sm:w-[40rem] md:w-[50rem] lg:w-[40rem]'>
                    <img src={Lightimage} alt="LightImage" className='relative min-[200px]:h-[15rem] min-[300px]:h-[17rem] min-[400px]:h-[18rem] lg:h-[20rem]  border-solid border-2 shadow-lg hover:shadow-xl ml-10 lg:ml-5 ' />
                  </div>
              </div>

                  <div className="lg:w-1/2 md:h-68  p-2 shadow-xl border-y-4 border-amber-400 ">
                      <span>
                        <h4 className='text-center font-Unbounded  pt-5 text-xl sm:text-2xl md:text-3xl  text-cyan-900'>A Place to remember</h4>
                      </span>
                      <span>
                        <p className=' font-Itim text-lg sm:text-xl md:text-2xl  p-5 pb-5 indent-10 text-cyan-900'>Janaki Hall - One stop solution for complete Wedding Services to make the occasion a memorable one. An Elegant and Spacious A/C Hall, suitable to celebrate all sorts of functions and events in an exquisite manner. Highly affordable.</p>
                      </span>
                  </div> 
              </div>   
            </section>


            {/* About */}
            <section >
              <div className=' bg-leaf bg-no-repeat bg-cover'>
                <div >
                  <div >
                    <span>
                    <div>
                      <div>
                        <div className='flex flex-row  items-center justify-center h-40   '>
                          <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                          <span>
                            <h4 className='text-2xl hover:text-3xl  md:text-4xl md:hover:text-6xl duration-300  font-Unbounded p-10 text-cyan-900 text-center '>About Us</h4>
                          </span>
                          <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                        </div>
                      </div>
                    </div>
                    </span>
                    <span className='flex flex-wrap items-center justify-center gap-10 '>
                      
                      <span className='lg:w-1/2   indent-10 shadow-lg p-5 leading-10 bg-white  border-y-4 border-amber-400'>
                      <p className=' font-Itim pt-10  text-lg sm:text-xl md:text-2xl lg:text-3xl  text-cyan-900'>
                          Janaki Hall is situated in a Calm and Serene Environment away from Bustling Traffic avoiding Air and Noise Pollution . Easily reachable by Bus and other vehicles.                  

                          </p><br/>
                          <p className=' font-Itim text-lg sm:text-xl md:text-2xl lg:text-3xl text-cyan-900'>We provide Holistic services for marriages and other functions to our customers.</p>

                      </span>
                      <span><img src={Dining} alt = "Dining" className='h-96 '></img></span>
                    </span>
                  </div>
                </div>

                <section className='container mx-auto px-10'>
                    <div className=' shadow-xl md:border-2 border-cyan-900 p-5 mt-10 rounded-lg md:rounded-3xl lg:rounded-full'>
                      <div className='flex flex-wrap  items-center gap-3'>
                          <div className='p-3  w-24'>                       
                           <img src={Airconditioner} alt='Air Conditioner' className='h-10' ></img>
                          </div>
                          <div>
                            <h5 className=' text-sm sm:text-md md:text-md  tracking-wide font-gabefont font-semibold'>Main Hall provided with World-Class Japanese Make A/C Cassettes to provide Comfortable Cool Atmosphere.<br/>Oscillating Fan options are also provided in case the party doesn’t want to turn the A/C on.</h5>
                          </div>
                      </div>                      
                    </div>
                    <div className=' shadow-xl md:border-2 border-cyan-900 p-5 mt-10  rounded-lg md:rounded-3xl lg:rounded-full w-full'>
                      <div className='flex flex-wrap items-center gap-3'>
                          <div className='  p-5 w-24'>
                       {/* <TbAirConditioning/> */}
                           <img src={Electricgenerator} alt='Air Conditioner' ></img>

                          </div>
                          <div>
                            <h5 className=' text-sm sm:text-md md:text-md tracking-wide font-gabefont font-semibold'>Stand-by Diesel Generator available in-house to give Instant Change-Over when main power fails ensuring<br/> Seamless Power Availability.</h5>
                          </div>
                      </div>                      
                    </div>
                    <div className=' shadow-xl md:border-2 border-cyan-900 p-5 mt-10 rounded-lg md:rounded-3xl lg:rounded-full w-full'>
                      <div className='flex flex-wrap  items-center gap-3'>
                          <div className='  p-5 w-24'>
                       {/* <TbAirConditioning/> */}
                           <img src={Table} alt='Table' ></img>

                          </div>
                          <div>
                            <h5 className=' text-sm sm:text-md md:text-md tracking-wide font-gabefont font-semibold'>Dining Hall under positive pressure to prevent foul or polluted air coming into the Hall.</h5>
                          </div>
                      </div>                      
                    </div>
                    <div className=' shadow-xl md:border-2 border-cyan-900 p-5 mt-10 rounded-lg md:rounded-3xl lg:rounded-full w-full'>
                      <div className='flex  flex-wrap items-center gap-3'>
                          <div className='  p-5 w-24'>
                       {/* <TbAirConditioning/> */}
                           <img src={Room} alt='Room' ></img>

                          </div>
                          <div>
                            <h5 className='inline-block  text-sm sm:text-md md:text-md tracking-wide font-gabefont font-semibold'>There are 8 guest rooms and all the guest rooms are provided with Solar Water Heating and Geyser Facilities.<br/> Bride and Groom rooms are provided with Room Air conditioners in addition.</h5>
                          </div>
                      </div>                      
                    </div>
                
                </section>
                
                     <div className=' text-end p-5 ' >
                        <h4 className=' font-Rowdies text-lg sm:text-xl md:text-2xl lg:text-3xl lg:mr-10 underline decoration-2 decoration-amber-400 text-cyan-900 '><Link to="/About">Read more...</Link></h4>
                      </div>
                

              </div>
            </section>

            {/* Facilities */}
            <section className=' bg-slate-100'>
              <div>
                <div>
                      <div className='flex flex-row  items-center justify-center h-40'>
                        <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                            <span>
                              <h4 className='text-2xl hover:text-3xl  md:text-4xl md:hover:text-6xl duration-300  font-Unbounded p-10 text-cyan-900 text-center'>Janaki Hall Facilities</h4>
                            </span>
                        <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                      </div>
                      <div>
                        <div className='flex flex-wrap items-center justify-evenly gap-10 p-1 sm:p-5 w-full '>

                          <span className='text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-Itim font-semibold text-cyan-100 shadow-2xl border-4 border-amber-400  '>
                            <p className='p-6 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Spacious Kitchen with Modern Equipment , Fume Extraction and Exhaust Facilities facilitating Quicker, Cleaner and Hygienic Cooking for large number of Guests.</p>
                          </span>

                          <span className='text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-Itim font-semibold text-cyan-100 shadow-2xl border-4 border-amber-400  '>
                            <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Seating Capacity – 350;<br/>Floating Capacity –<br/> 800-1000;<br/>Dining Capacity at any one instant – 150</p>
                          </span>

                          <span className='text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-Itim font-semibold text-cyan-100 shadow-2xl border-4 border-amber-400 '>
                            <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72  lg:w-96'>In-House Catering facility available to provide quality and hygienic food at economic rates.</p>
                          </span>                        
                        
                        </div>

                      </div>

                      <div className=' text-end p-5 ' >
                        <h4 className=' font-Rowdies text-lg sm:text-xl md:text-2xl lg:text-3xl lg:mr-10 underline decoration-2 decoration-amber-400 text-cyan-900 '> <Link to="/Facilities">Read more...</Link></h4>
                      </div>
                </div>
              </div>
            </section>

              {/* Gallery */}
            <section className='bg-white'>
                      <div className='flex flex-row  items-center justify-center h-40 '>
                        <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                            <span>
                              <h4 className='text-2xl hover:text-3xl  md:text-4xl md:hover:text-6xl duration-300  font-Unbounded p-10 text-cyan-900 text-center'>Gallery</h4>
                            </span>
                        <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                      </div>

                      <div className=' flex flex-wrap items-center justify-center gap-10'>
                          <span className='hover:shadow-2xl border-4 border-amber-400'>
                            <img src={Image1} alt='' className='h-80 p-5 ' />
                          </span>
                          <span className='hover:shadow-2xl border-4 border-amber-400'>
                            <img src={Image2} alt='' className='h-80 p-5' />
                          </span>
                          <span className='hover:shadow-2xl border-4 border-amber-400'>
                            <img src={Image3} alt='' className='h-80 p-5' />
                          </span>
                      </div>

                      <div className=' text-end p-5 ' >
                        <h4 className=' font-Rowdies text-lg sm:text-xl md:text-2xl lg:text-3xl lg:mr-10 underline decoration-2 decoration-amber-400 text-cyan-900 '> <Link to="/Gallery" >Read more...</Link></h4>
                      </div>
            </section>

            
        

      </div>
      
    </div>
  )
}

export default Home;