import React, { useRef } from 'react';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import Building from '../../Images/building.png';
import Telephone from '../../Images/telephone.png';
import Mail from  '../../Images/mail.png';
import emailjs from '@emailjs/browser';




const Contactus = () => {


  const form =useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
     .sendForm(
      'react_contact_details',
      'template_lzyvuqa',
      form.current,
      '1d9zKsQ6RYI-phK6J'
     )
     .then(
      (result) => {
        alert('Message sent successfully...');
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
      );
     
  };
  return (
    <div className='mt-24 '>

          

              {/* Header */}
            <section className=' mt-10'>
              <div>
                <div>
                  <div className='flex flex-row  items-center justify-center   '>
                    <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                    <span>
                      <h4 className='text-md sm:text-lg md:text-xl lg:text-4xl font-Unbounded p-10 text-indigo-800 text-center'>Get In Touch, Let's Celebrate!!</h4>
                    </span>

                    <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                  </div>
                </div>
              </div>
            </section>

            {/* Contact Details */}

            <section className=''>
              <div>
                <div className=' bg-bg-design bg-no-repeat  bg-cover p-10  '>
                  
                  <div className=' '>
                    <div className='w-full flex flex-wrap items-center justify-evenly'>
                      <span className=' flex flex-col items-center'>
                        <img src={Telephone} alt="" className=' h-14 '/>
                        <p className=' pt-5 text-center text-md sm:text-lg md:text-xl lg:text-2xl font-Itim font-semibold text-indigo-800'>+91 88386 74574<br/>
                           +91 88705 57892</p>

                      </span>
                      <span className=' flex flex-col items-center '>
                        <img src={Building} alt="" className=' h-14 '/>
                        <address className=' pt-5 text-center text-md sm:text-lg md:text-xl lg:text-2xl font-Itim font-semibold text-indigo-800'>
                        51, Perumal Koil Street,<br/> Sundappalayam, Coimbatore - 641007.
                        </address>

                      </span> 
                      <span className=' flex flex-col items-center'>
                        <img src={Mail} alt="" className=' h-14'/>
                          <p  className='  pt-5 text-center text-md sm:text-lg md:text-xl lg:text-2xl font-Itim font-semibold text-indigo-800'>janakihall0327@gmail.com</p>

                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
   
        <section className='' >
                   <span className='flex flex-col md:ml-40  lg:ml-52 text-cyan-900   p-5 mt-10'>
                      <h4 className='tex-xs sm:text-lg md:text-xl lg:text-2xl font-Rowdies text-left'>Have Questions/ Feedback/ Comments?</h4>
                      <p className='text-xs sm:text-md md:text-lg lg:text-xl text-left font-semibold '>Write to Janakihall0327@gmail.com or submit the form below.</p>
                    </span>
          <div className= 'p-10 w-full '>                   
            <div className='p-5'>                    
                <div className=' flex flex-wrap justify-center items-center gap-5  '>
                      <div className='bg-slate-100'>
                       <div className='shadow-lg p-10  '>                  
                              <form className='text-cyan-900' ref={form} onSubmit={sendEmail}>
                                <label htmlFor="name" className='min-[100px]:ml-10 pr-4 p-10 text-sm sm:text-md md:text-lg lg:text-xl font-semibold '>Name:</label>
                                  <input type='text' name='user_name'
                                  placeholder='Narayanan' 
                                  className='min-[100px]:ml-10 md:ml-12 p-2 pl-3  border-2 border-cyan-800 font-semibold text-sm sm:text-md md:text-lg lg:text-xl'
                                 /><br/><br/>
                                 
                                  <label htmlFor="number" className='min-[100px]:ml-10 pr-3 p-10 text-sm sm:text-md md:text-lg lg:text-xl font-semibold'>Mobile:</label>
                                  <input type="tel"
                                   className='min-[100px]:ml-10  p-2 pl-3  border-2 border-cyan-800 font-semibold text-sm sm:text-md md:text-lg lg:text-xl'                                   
                                   name="user_number" 
                                   placeholder="9871238892" 
                                   pattern="[0-9]{10}"
                                   />
                                   <br/><br/>
                                  <label htmlFor="mail" className='min-[100px]:ml-10 pr-2 p-10 text-sm sm:text-md md:text-lg lg:text-xl font-semibold'>Email Id:</label>
                                  <input type="email"  name='user_email'
                                  className= 'min-[100px]:ml-10 p-2 pl-3  border-2 border-cyan-800 font-semibold text-sm sm:text-md md:text-lg lg:text-xl'  
                                  placeholder="sfjl@gmail.com"
                                /><br/><br/>
                                  <p className='p-10 text-2xl font-semibold min-[100px]:ml-10'>Requirement in Brief:</p>
                                  <textarea className='p-2 h-40 lg:h-48  lg:w-96 ml-20 border-2 border-cyan-800 font-semibold text-sm sm:text-md md:text-lg lg:text-xl' 
                                  name="message"  
                                  placeholder="Enter Your Message Here.."
                                 
                                  ></textarea><br/><br/>                                  
                                  <button type='submit' className=" px-10 py-2 bg-slate-100 text-center ml-28 lg:ml-40 border-2 border-cyan-900 rounded-md text-sm sm:text-md md:text-lg lg:text-xl font-gabefont font-semibold text-cyan-800 hover:bg-cyan-800 hover:text-slate-50 ">Submit</button>
                              </form>			
                        </div>                         
                      </div>
                  {/* Location */}
                  <div>
                     <iframe  className='h-80 lg:h-[40rem] border-4 border-cyan-800  lg:w-[50rem] rounded-xl' title='location'   frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1325.9870962099296!2d76.87243681824475!3d11.000711672999508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85ffee0fb5517%3A0xefc23a7ca559005d!2sJANAKI%20HALL%20%7C%20Ac%20Marriage%20hall!5e0!3m2!1sen!2sin!4v1678342864245!5m2!1sen!2sin" ></iframe>
                  </div>
                </div>
            </div>

            

          </div>
        </section>

    </div>
  )
}

export default Contactus;