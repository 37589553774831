import React from 'react';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import Hall from '../../Images/DSC00011123.jpg';
import {ScrollContainer, Animator, Sticky,
  batch, Fade, MoveIn} from 'react-scroll-motion';

const About = () => {
  
  return (
    <div className=' w-full'>

<section className=' hidden lg:block'>  
         <ScrollContainer >
          <Animator animation={batch(Fade(), Sticky(), MoveIn(0, -10))} >
              <div className='flex flex-row  items-center justify-center bg-slate-900/70 mt-[25rem] lg:mt-0'>
                <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                <span>
                  <h4 className=' text-sm sm:text-xl p-5 md:text-3xl lg:text-4xl font-Unbounded md:p-10 text-white text-center'>About Janaki Hall</h4>
                </span>
                <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
              </div>              
          </Animator> 
          </ScrollContainer>  
    
    </section>


       {/* Header */}

       <section className=' mt-15'>
              <div>
                <div>
                  <div className='flex flex-row items-center justify-center   '>
                    <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                    <span>
                      <h4 className=' text-md sm:text-lg md:text-xl lg:text-4xl font-Unbounded p-10 text-indigo-800 text-center'>Why Choose Janaki Hall?</h4>
                    </span>
                    <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                  </div>
                </div>
              </div>
            </section>
        {/* About Janaki Mahal */}

            <section className='mb-20'>
              <div className='flex flex-wrap justify-around  items-center gap-3'>
                <div className='shadow-lg'>
                  <img src={Hall} alt="Hall" className=' min-[200px]:h-[15rem] min-[400px]:h-[20rem] sm:h-[25rem] md:h-[20rem] p-5' />
                </div>
                <div className=' text-left  text-cyan-900 font-semibold hover:shadow-lg border-y-4 border-amber-300 '>
                    <p className='indent-5 leading-relaxed lg:leading-loose p-5  '>
                            <span className="font-bold  text-xl pr-2  "> Janaki Hall</span>
                            <span className=' font-gabefont text-lg sm:text-lg  '> - One stop solution for complete Wedding Services to make the occasion a memorable one.
                          An Elegant and Spacious A/C Hall, suitable to celebrate all sorts of functions and events in an Exquisite manner. Highly affordable.</span>   
                          <br/><br/>
                          <p>	
                             <span className="font-bold  text-xl pr-2 "> Location</span> 
                             <span  className=' font-gabefont text-lg sm:text-lg '> - Close Proximity to the temple makes visits to the temple during the function before or after very convenient and specially when one wants to conduct solemn ceremonies at the temple while having Reception , Lunch and/or Dinner at the Marriage Hall involving much larger number of people. The Mandapam can be easily accessible by Road. Bus facilities available from Main Bus stand and Railway station.</span>
                          </p><br/>
                          <p className=' font-gabefont text-lg sm:text-lg'>
                          Janaki Hall is situated in a Calm and Serene Environment away from Bustling Traffic avoiding Air and Noise Pollution . Easily reachable by Bus and other vehicles.                  

                          </p>
                      </p><br/><br/>
                </div>
              </div>
            </section>

            <section className=' mb-10'>
              <div className='bg-slate-100 p-10'>
                <div className=''>
                  <h4 className='text-center font-Unbounded text-md sm:text-lg md:text-xl lg:text-4xl text-slate-800 '>Advantages - Janaki Hall</h4>
                  <p className='text-center font-Itim text-lg sm:text-xl md:text-xl lg:text-2xl leading-5 text-cyan-900 pb-5 '>We provide Holistic services for marriages and other functions to our customers.<br/> Our main aim is to be a one-stop solution for complete wedding services to make the concept of wedding planning easier for our customers.</p>
                </div>
                      <span className='text-center font-Unbounded text-md sm:text-lg md:text-xl lg:text-4xl'>
                        <h4 className='text-slate-800'>Policies</h4>
                      </span>
                <div className=' grid place-content-center pb-3'>
                      
                       <span className=''>
                          <h4 className='text-cyan-900  text-lg sm:text-xl md:text-xl lg:text-2xl  font-Itim '>* Alcohol is prohibited in the building premises</h4>
                        </span>
                        <span className=''>
                          <h4 className='text-cyan-900 text-lg sm:text-xl md:text-xl lg:text-2xl  font-Itim '>* Smoking is prohibited in the Main Hall and Dining Hall</h4>
                        </span>
                        <span className=''>
                          <h4 className=' text-cyan-900 text-lg sm:text-xl md:text-xl lg:text-2xl  font-Itim '>* Strictly Vegetarian policy within building premises</h4>
                        </span>

                </div>
                <div className='flex flex-wrap items-center justify-evenly gap-y-10 md:p-10'>

                  <div className= 'shadow-xl bg-cyan-800 w-[40rem] p-3 md:p-10 border-4 border-amber-300 hover:shadow-lg rounded-md'>
                    <span>
                       <p className='text-center text-md sm:text-lg md:text-xl lg:text-3xl font-Unbounded text-slate-50 p-2 md:mt-5'>Dining Hall</p>
                       <p className='text-center text-sm sm:text-md md:text-lg lg:text-xl font-Itim text-slate-50 sm:p-2'>
                          High Quality S.S. Tables to provide comfortable Dining Experience for Guests. Oscillating Fan options are available in the Dining Hall. 
                      </p>
                    </span>
                  </div>
                  <div className= ' shadow-xl bg-cyan-800  w-[40rem] p-3 md:p-10  border-4 border-amber-300 hover:shadow-lg rounded-md' >
                    <span className=''>
                       <p className='text-center text-md sm:text-lg md:text-xl lg:text-3xl font-Unbounded text-slate-50 p-2 '>Main Hall</p>
                       <p className='text-center text-sm sm:text-md md:text-lg lg:text-xl font-Itim text-slate-50 p-2 '>
                          Spacious Stage provided with Granite Flooring , Flood lighting and Well Illuminated. 
                          World Class A/C Cassettes made by Top Japanese Companies to give Reliable Comfortable cooling. 
                          Unhindered view of Stage from almost anywhere in the Hall. 
                          High Performance Wall-Mounted Fans available if and when A/C is not required by Guests. 
					              </p>
                    </span>
                  </div>
                
                </div>
              </div>
            </section>
            
        
    </div>
  )
}

export default About;