import React from 'react';
import {BsFillSuitHeartFill} from 'react-icons/bs';
import {MdLocationOn} from 'react-icons/md';
import {MdCall} from 'react-icons/md' ;
import {HiMail} from 'react-icons/hi';



const footer = () => {
  return (
    <div className='bg-slate-800 w-full '>
        <footer className='inset-x-0 relative bottom-0  w-full  bg-slate-800 '>
            <div className='  p-12  ' >
                <div className='flex flex-wrap justify-center  items-center'>
     
                        {/* address */}
                        <section>
                            <span><h4 className='text-slate-100 font-gabefont font-semibold text-sm sm:text-md md:text-lg lg:text-2xl text-left '>Contact us to celebrate your special day with Us !!</h4></span>
                             <br/> <br/>   
                            <span className='text-slate-100 font-gabefont font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl '><h1 className='flex gap-3 items-center'><MdLocationOn className=' h-6 md:h-10 w-8' /> Location</h1></span>
                            <address className='text-md sm:text-lg md:text-xl lg:text-2xl font-gabefont text-slate-100 leading-8 ml-9 '>
                            51, Perumal Koil Street,<br/>Sundappalayam,<br/> Coimbatore - 641007.
                            </address><br/>
                            <span>
                                <h4 className='text-slate-100 font-gabefont font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl flex gap-3 items-center '> <MdCall className=' h-6 md:h-10 w-8'/>Call Us</h4>
                                <p className='text-slate-100 font-gabefont italic text-md sm:text-lg md:text-xl lg:text-2xl leading-8 ml-9'>+91 88386 74574<br/>
                                   +91 88705 57892</p>
                            </span><br/>
                            <span>
                            <h4 className='text-slate-100 font-gabefont font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl flex gap-3 items-center '> <HiMail className=' h-6 md:h-10 w-8'/>Mail Us</h4>
                                <p className='text-slate-100 font-gabefont italic text-md sm:text-lg md:text-xl lg:text-2xl leading-8 ml-9'>janakihall0327@gmail.com</p>

                            </span>
                            
                        </section>
                         {/* Map */}
                         <section>
                            <h4 className='text-slate-100 font-sans font-bold  text-2xl pb-5'>Find us on the map</h4>
                        
                             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1325.9870962099296!2d76.87243681824475!3d11.000711672999508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85ffee0fb5517%3A0xefc23a7ca559005d!2sJANAKI%20HALL%20%7C%20Ac%20Marriage%20hall!5e0!3m2!1sen!2sin!4v1678342864245!5m2!1sen!2sin" height="300" className=' w-[12rem] sm:w-[30rem] md:[40rem] lg:w-[50rem]'

                              title='location' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                        </section>                     
                </div>
                <section className='text-center text-xs md:text-xl mb-2 mt-5'>
                    <h1 className='flex flex-row justify-center items-center gap-x-2 text-white '>Designed with<BsFillSuitHeartFill color='red'/>by Nimbusgurus Software Solutions</h1>
                </section>

        
            </div>           
        </footer>
       
    </div>
  )
}

export default footer;