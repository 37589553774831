import React from 'react';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import {ScrollContainer, Animator, Sticky,
  batch, Fade, MoveIn} from 'react-scroll-motion';

const Facilities = () => {

  return (
    <div className='mt-20'>

      <ScrollContainer className='hidden lg:block'>
          <Animator animation={batch(Fade(), Sticky(), MoveIn(0, -10))} >
              <div className='flex flex-row  items-center justify-center bg-slate-900/70 mt-[25rem] lg:mt-0  '>
                <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                <span>
                  <h4 className=' text-sm sm:text-xl p-10 md:text-3xl lg:text-4xl font-Unbounded  text-white text-center'>Janaki Hall Facilities</h4>
                </span>
                <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
              </div>              
          </Animator> 
          </ScrollContainer>
       {/* Header */}

       <section className='mt-24'>
              <div>
                <div>
                  <div className='flex flex-row  items-center justify-center   '>
                    <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                    <span>
                      <h4 className=' text-md sm:text-lg md:text-xl lg:text-4xl font-Unbounded p-10 text-indigo-800 text-center'>Janaki Hall Facilities</h4>
                    </span>
                    <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                  </div>
                </div>
              </div>
        </section>

        {/*Facilities */}
        <section className='mt-10 mb-10'>
          <div >
            <div className='flex flex-wrap items-center justify-evenly gap-10 '>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Main Hall provided with World-Class Japanese Make A/C Cassettes to provide Comfortable Cool Atmosphere.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>In-House Catering facility available to provide quality and hygienic food at economic rates.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Seating Capacity – 350;<br/>Floating Capacity –<br/> 800-1000; <br/>Dining Capacity – 150</p>
              </span>
              <span className='text-center text-lg sm:text-xl lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-3 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>There are 8 guest rooms and all the guest rooms are provided with Solar Water Heating and Geyser Facilities.<br/> Bride and Groom rooms are provided with Room Air conditioners in addition.</p>
              </span>
              <span className='text-center text-lg sm:text-xl lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-8 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Spacious Kitchen with Modern Equipment , Fume Extraction and Exhaust Facilities facilitating Quicker, Cleaner and Hygienic Cooking for large number of Guests.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Dining Hall under positive pressure to prevent foul or polluted air coming into the Hall.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Stand-by Diesel Generator available in-house to give Instant Change-Over when main power fails ensuring Seamless Power Availability.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 lg:pt-24 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Car Parking facility available.</p>
              </span>
              <span className='text-center text-lg sm:text-xl lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Well Designed Lighting System using LED Lights to give Optimal Brightness economically to give Good Performance at minimum Electricity Charges.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Fully Furnished Guest Rooms with attached bathrooms and with storage and locker facilities.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Bridal Make-up, Artificial Jewellery for rent and Costume designing, Blouse stitching.</p>
              </span> 
            </div>
          </div>
        </section>
      
    </div>
  )
}

export default Facilities;