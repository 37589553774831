import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from "./Nav";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contactus";
import Facilities from "../pages/Facilities";
import Gallery from "../pages/Gallery";
import Additionalservice from "../pages/Additionalservice";




const Navlinks = () => {


  
  return (
    <div>
   
      <Router >        
        <Routes >
          <Route path="/" element ={<Nav/>}>
            <Route index element={<Home/>} />
            <Route path='About' element={<About/>} />
            <Route path='Facilities' element={<Facilities/>}  />
            <Route path='Gallery' element={<Gallery/>} />
            <Route path='Contact' element={<Contact/>} />
            <Route path='Additionalservice' element={<Additionalservice/>} />
          </Route>
        </Routes> 
      </Router>

      
      
    </div>
  )
}

export default Navlinks;