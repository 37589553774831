import React from 'react';
import Navlink from './component/header/Navlinks';
import Footer from './component/Footer/footer';



const App = () => {
  return (
    <div>
      <Navlink/>
     
      <Footer/>
     
    </div>
  )
}

export default App;
