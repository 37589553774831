import React from 'react';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import Image1 from '../../Images/IMG_2472.JPG';
import Image2 from '../../Images/IMG_2468.JPG';
import Image3 from '../../Images/IMG_2476.JPG';
import Image4 from '../../Images/IMG_6152.JPG';
import Image5 from '../../Images/IMG_2472.JPG';
import Image6 from '../../Images/DSC00009.jpg';
import Image7 from '../../Images/DSC03779.jpg';
import Image8 from '../../Images/DSC03776.jpg';
import Image9 from '../../Images/DSC00012.jpg';
import Image10 from '../../Images/IMG_6165.JPG';
import Image11 from '../../Images/DSC03772.jpg';
import Image12 from '../../Images/DSC03761.jpg';
import Image13 from '../../Images/DSC00025.jpg';
import Image14 from '../../Images/DSC00041.jpg'; 
import Image15 from '../../Images/DSC03265.jpg';
import Image16 from '../../Images/DSC00018.jpg';
import Image17 from '../../Images/DSC00021.jpg';
import Image18 from '../../Images/DSC03757.jpg';
import Image19 from '../../Images/DSC03789.jpg';
import Image20 from '../../Images/DSC03786.jpg';
import Image21 from '../../Images/IMG_2480.JPG';
import Image22 from '../../Images/IMG_2483.JPG';
import Image23 from '../../Images/IMG_2472.JPG';
import Image24 from '../../Images/DSC00021.jpg';
import {ScrollContainer, Animator, Sticky,
batch, Fade, MoveIn} from 'react-scroll-motion';


const Gallery = () => {

  return (
  
     <div className='bg-slate-100'>

{/* Header */}
<section className=' mt-10'>
        <div>
          <div>
            <div className='flex flex-row  items-center justify-center   '>
              <img src={Vecttop}  alt="vector" className='hidden lg:block '/>
              <span>
                <h4 className=' text-sm sm:text-xl md:text-3xl lg:text-4xl font-Unbounded p-10 text-indigo-800 text-center'>Janaki Hall Gallery</h4>
              </span>

              <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
            </div>
          </div>
        </div>
      </section>

           <ScrollContainer>
      <section className='hidden lg:block'>            
            <Animator animation={batch(Fade(), Sticky(), MoveIn(0, -10))} >
                <div className='flex flex-row  items-center justify-center bg-slate-900/70 mt-[25rem] lg:mt-0  '>
                  <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                  <span>
                    <h4 className=' text-5xl font-Unbounded p-10 text-white text-center'>Janaki Hall Gallery</h4>
                  </span>
                  <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                </div>              
            </Animator>        
      </section>

      {/* Images */}


      <section>
      <section className="overflow-hidden">
              <div className="container mx-auto px-5 py-2 lg:px-12 lg:pt-24">
                <div className="-m-1 flex flex-wrap md:-m-2">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image1} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image2} />
                    </div>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image3} />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image4} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image5} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image6} />
                    </div>
                  </div>                  
                </div>
                <div className="-m-1 flex flex-wrap md:-m-2">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image7} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image8} />
                    </div>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image9} />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image10} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image11} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image12} />
                    </div>
                  </div>                  
                </div>
                <div className="-m-1 flex flex-wrap md:-m-2">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image13} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image14} />
                    </div>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image15} />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image16} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image17} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image18} />
                    </div>
                  </div>                  
                </div>
                <div className="-m-1 flex flex-wrap md:-m-2">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image19} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image20} />
                    </div>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image21} />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image22} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image23} />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center border-4 border-amber-400"
                        src={Image24} />
                    </div>
                  </div>                  
                </div>
              </div>
</section>
      </section>
      
      </ScrollContainer>
</div>
 
  )
}

export default Gallery;