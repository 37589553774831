import React from 'react';
import Vecttop from '../../Images/getintouch1.png';
import Vectbottom from '../../Images/getintouch2.png';
import {ScrollContainer, Animator, Sticky,
  batch, Fade, MoveIn} from 'react-scroll-motion';

const Additionalservice = () => {


  return (
    <div className='w-full'>

<ScrollContainer className='hidden lg:block'>
          <Animator animation={batch(Fade(), Sticky(), MoveIn(0, -10))} >
              <div className='flex flex-row  items-center justify-center bg-slate-900/70 mt-[25rem] lg:mt-0  '>
                <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                <span>
                  <h4 className=' text-sm sm:text-xl md:text-3xl lg:text-4xl font-Unbounded p-10 text-white text-center'>Additional Services</h4>
                </span>
                <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
              </div>              
          </Animator> 
          </ScrollContainer>


             {/* Header */}

            <section className=' mt-20'>
              <div>
                <div>
                  <div className='flex flex-row  items-center justify-center   '>
                    <img src={Vecttop}  alt="vector" className='hidden lg:block'/>
                    <span>
                      <h4 className='  text-lg sm:text-xl md:text-3xl lg:text-4xl  font-Unbounded p-10 text-indigo-800 text-center font-semibold'>Janaki Mahal Services</h4>
                    </span>
                    <img src={Vectbottom}  alt="vector" className='hidden lg:block'/>
                  </div>
                </div>
              </div>
            </section>


              {/*Additional Services */}
        <section className='mt-10 mb-10'>
          <div >
            <div className='flex flex-wrap items-center justify-evenly gap-10 '>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Multipurpose Hall for Exhibitions / Performances / Events /Conferences etc.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>In-House Catering facility available to provide quality and hygienic food at economic rates.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Decorations, Lighting, Backstage setup.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Bridal Make-up, Artificial Jewellery for rent.</p>
              </span>
              <span className='text-center text-lg sm:text-xl  lg:text-2xl font-Itim font-semibold text-cyan-100 hover:shadow-xl border-4 border-cyan-900 rounded-lg'>
                <p className='p-10 bg-bgfacilities bg-no-repeat bg-cover lg:h-72 lg:w-96'>Costume designing, Blouse stitching.</p>
              </span>
              
            </div>
          </div>
        </section>
        

    </div>
  )
}

export default Additionalservice;